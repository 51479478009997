import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    Grid
} from "@mui/material";
import {CentreWrapper, Desc, RootStyle, Subheader, Header} from "../components";
import {ExpandLess} from "@mui/icons-material";
import {styled} from "@mui/material/styles";
import {MotionInView, varFadeInDown, varFadeInUp} from "../animate";

const Faq = () => {
    const [expanded, setExpanded] = React.useState("panel1");
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <RootStyle id="faqs">
            <Container maxWidth="lg">
                <Grid container direction="column">
                    <Grid item sx={{width: "100%"}}>
                        <MotionInView variants={varFadeInDown}>
                            <CentreWrapper>
                                <Desc>FAQs</Desc>
                                <Header>Frequently Asked Questions</Header>
                                <Subheader
                                    sx={{
                                        mb: "40px",
                                        textAlign: "center",
                                        width: {xs: "100%", md: "60%"}
                                    }}>
                                    Here we&apos;ve outlined some common inquiries. If you have other questions, please reach out via the contact form below.
                                </Subheader>
                            </CentreWrapper>
                        </MotionInView>
                    </Grid>
                    <Grid item sx={{width: "100%"}}>
                        <MotionInView variants={varFadeInUp}>
                            {faqItems.map((generalItem) => (
                                <FaqAccordion
                                    expanded={expanded === generalItem.panel}
                                    onChange={handleChange(generalItem.panel)}
                                    key={generalItem.id}>
                                    <FaqAccordionSummary
                                        aria-controls={
                                            generalItem.togglerId + "d-content"
                                        }
                                        id={generalItem.togglerId + "d-header"}
                                        expandIcon={<FaqExpandLess />}>
                                        {generalItem.question}
                                    </FaqAccordionSummary>
                                    <FaqAccordionDetails>
                                        {generalItem.answer}
                                    </FaqAccordionDetails>
                                </FaqAccordion>
                            ))}
                        </MotionInView>
                    </Grid>
                </Grid>
            </Container>
        </RootStyle>
    );
};

const faqItems = [
    {
        id: 1,
        question: "What is Indocia?",
        answer: "Indocia is a peer-to-peer cryptocurrency exchange that allows users to trade directly with one another without the need for a centralized authority. This platform supports a variety of cryptocurrencies and offers a range of payment methods, enhancing flexibility for users globally.",
        panel: "panel1"
    },
    {
        id: 2,
        question: "How does Indocia ensure transaction security?",
        answer: "Indocia employs several security measures such as escrow services and a robust reputation system. These features help to safeguard transactions and ensure that funds are only released when both parties fulfill their obligations, thus minimizing the risk of fraud.",
        panel: "panel2"
    },
    {
        id: 3,
        question: "What are the fees associated with using Indocia?",
        answer: "Indocia benefits from the inherent advantages of P2P platforms by not charging any fees or commissions on transactions. This structure can significantly reduce the cost of trading compared to centralized exchanges",
        panel: "panel3"
    },
    {
        id: 4,
        question: "Can I trade without completing KYC verifications? ",
        answer: "Yes, Indocia allows users to trade without mandatory KYC verifications, offering greater privacy. However, optional KYC might be available for users who prefer to enhance the trustworthiness of their transactions.",
        panel: "panel4"
    },
    {
        id: 5,
        question: "What should I do if I encounter a dispute during a transaction?",
        answer: "In the event of a dispute, Indocia provides mechanisms for resolution, which may include the involvement of arbitrators from the community. These arbitrators listen to both sides and help resolve the conflict, ensuring a fair outcome for all parties involved.",
        panel: "panel5"
    },
    {
        id: 6,
        question: "How does Indocia handle regulatory issues? ",
        answer: "While P2P exchanges like Indocia operate with a degree of immunity from direct governmental interference, they still adhere to the regulatory frameworks of the jurisdictions in which they operate. Users are advised to stay informed about the legal landscape in their respective regions to mitigate any potential legal risks",
        panel: "panel6"
    }
];

const FaqAccordion = styled(Accordion)(({theme}) => ({
    border: "none",
    background: "transparent",
    padding: 8,
    "&:not(:first-of-type)": {
        marginTop: 10
    },
    "&.Mui-expanded": {
        border: "none",
        borderTop: `1px solid ${theme.palette.divider}`,
        borderRadius: 0,
        boxShadow: "none",
        "&:first-of-type": {
            borderTop: "none"
        }
    },
    [theme.breakpoints.down("md")]: {
        "&:first-of-type": {
            marginTop: 40
        }
    }
}));
const FaqAccordionSummary = styled(AccordionSummary)(({theme}) => ({
    ...theme.typography.h6,
    fontWeight: 400,
    color: theme.palette.text.primary
}));
const FaqAccordionDetails = styled(AccordionDetails)(({theme}) => ({
    ...theme.typography.body1,
    color: theme.palette.grey[500]
}));
const FaqExpandLess = styled(ExpandLess)(({theme}) => ({
    color: theme.palette.grey["400"],
    fontSize: 30
}));

export default Faq;
