import {useCallback, useEffect, useState} from "react";
import {useRequest} from "hooks/useRequest";

const useCoincapApi = (id) => {
    const [request, loading] = useRequest();
    const [data, setData] = useState({});
    const fetchData = useCallback(() => {
        request
            .get(`https://api.coincap.io/v2/assets/${id}`)
            .then((dataObj) => {
                const {data} = dataObj;
                setData({
                    ...data,
                    price: data.priceUsd,
                    rank: data.rank,
                    marketCap: data.marketCapUsd,
                    percentChange: data.changePercent24Hr
                });
            });
    }, [request, id]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return [data, loading];
};

export default useCoincapApi;
