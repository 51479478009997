import React, {useState} from "react";
import ThemeConfig from "../theme";

import {
    About,
    Contact,
    Count,
    CryptoSlide,
    Faq,
    Features,
    Footer,
    Header,
    Hero,
    Payment,
    Profile,
    Settings,
    Sidebar
} from "./components";
import {Box, useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";

const Landing = () => {
    const [isOpen, setIsOpen] = useState(false);
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up("md"), {
        defaultMatches: true
    });
    const open = isMd ? false : isOpen;

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <ThemeConfig>
            <Box sx={{overflow: "hidden"}}>
                <Header isOpen={isOpen} toggle={toggle} />
                <Sidebar toggle={toggle} open={open} />
                <Hero />
                <Payment />
                <About />
                <CryptoSlide />
                <Features />
                <Count />
                <Profile />
                <Faq />
                <Contact />
                <Footer />
                <Settings />
            </Box>
        </ThemeConfig>
    );
};

export default Landing;
