import React from "react";
import {styled} from "@mui/material/styles";
import {
    Box,
    IconButton,
    List,
    Toolbar,
    Typography,
    useScrollTrigger
} from "@mui/material";
import {animateScroll as scroll, Link as ScrollLink} from "react-scroll";
import {Icon} from "@iconify/react";
import {ContainedButton, OutlinedButton} from "../components";
import LogoSvg from "./components/logoSvg";

const Header = ({toggle, isOpen}) => {
    const toggleHome = () => {
        scroll.scrollToTop();
    };

    function ElevationScroll(props) {
        const {children, window} = props;

        const trigger = useScrollTrigger({
            disableHysteresis: true,
            threshold: 0,
            target: window ? window() : undefined
        });

        return React.cloneElement(children, {
            elevation: trigger ? 4 : 0
        });
    }

    return (
        <ElevationScroll>
            <Navbar disableGutters isopen={isOpen}>
                <LogoContainer onClick={toggleHome}>
                  <LogoSvg />
                </LogoContainer>
                <Box sx={{display: {xs: "none", md: "block"}}}>
                    <NavLinksContainer>
                        {navItems.map((navItem) => (
                            <NavLinks
                                key={navItem.id}
                                to={navItem.idm}
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                                offset={-100}>
                                {navItem.name}
                            </NavLinks>
                        ))}
                    </NavLinksContainer>
                </Box>
                <Box
                    sx={{
                        display: {xs: "none", md: "flex"},
                        alignItems: "center",
                        position: "absolute",
                        right: "10%"
                    }}>
                    <ButtonContainer>
                        <PageLink href="/auth/login">
                            <OutlinedButton> Sign in </OutlinedButton>
                        </PageLink>
                        <PageLink href="/auth/register">
                            <ContainedButton>Sign up</ContainedButton>
                        </PageLink>
                    </ButtonContainer>
                </Box>

                <Box
                    sx={{
                        display: {xs: "flex", md: "none"},
                        alignItems: "center",
                        position: "absolute",
                        right: "10%"
                    }}>
                    <NavIconButton onClick={toggle}>
                        <NavIcon icon="material-symbols:menu-rounded" />
                    </NavIconButton>
                </Box>
            </Navbar>
        </ElevationScroll>
    );
};

const Navbar = styled(Toolbar)(({theme}) => ({
    height: 80,
    width: "100%",
    position: "fixed",
    background: theme.palette.background.default,
    backdropFilter: "blur(6px)",
    opacity: 0.9,
    top: 0,
    left: 0,
    transition: ".35s ease-in-out",
    zIndex: 999
}));
const LogoContainer = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    position: "relative",
    marginLeft: "10%",
    height: "inherit",
    cursor: "pointer",
    [theme.breakpoints.down("lg")]: {
        marginLeft: "5%"
    }
}));
const Logo = styled(Typography)(({theme}) => ({
    ...theme.typography.h3,
    fontWeight: 700,
    color: theme.palette.primary.main
}));

const NavLinksContainer = styled(List)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    marginLeft: 100,
    [theme.breakpoints.down("lg")]: {
        marginLeft: 50
    }
}));
const NavLinks = styled(ScrollLink)(({theme}) => ({
    fontFamily: theme.typography.fontFamily,
    position: "relative",
    margin: "0px 20px",
    padding: "10px 0px",
    fontWeight: 500,
    color: theme.palette.text.primary,
    cursor: "pointer",
    fontSize: "0.9rem",
    "&::after": {
        content: "''",
        height: 2,
        width: 0,
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
        background: theme.palette.primary.main,
        transition: "width .3s ease-in-out"
    },
    "&:hover::after": {
        width: "100%",
        transition: "width .5s ease-in-out"
    },
    [theme.breakpoints.down("lg")]: {
        margin: "0px 10px"
    }
}));
const ButtonContainer = styled(Box)(() => ({
    display: "flex",
    alignItems: "center"
}));
const NavIconButton = styled(IconButton)(() => ({
    position: "absolute",
    right: "10%"
}));
const NavIcon = styled(Icon)(({theme}) => ({
    fontSize: "30px",
    color: theme.palette.primary.main
}));
const PageLink = styled("a")(() => ({
    textDecoration: "none",
    "&:not(:first-of-type)": {
        marginLeft: 5
    }
}));
export const navItems = [
    {id: 1, idm: "home", name: "Home"},
    {id: 2, idm: "about", name: "About"},
    {id: 3, idm: "features", name: "Features"},
    {id: 4, idm: "faqs", name: "FAQs"},
    {id: 5, idm: "contact", name: "Contact"}
];

export default Header;
