import * as React from "react";
import {styled, useTheme} from "@mui/material/styles";

function LogoSvg({scrollNav}) {
    const theme = useTheme();
    const color = theme.palette.mode === "dark" ? "#fff" : "#000e00";

    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={120}
            viewBox="0 0 90 30.000001"
            height={40}
        >
            <defs>
                <clipPath id="a">
                    <path d="M77 10h11.547v12H77zm0 0"/>
                </clipPath>
                <clipPath id="b">
                    <path d="M2 7h15v15H2zm0 0"/>
                </clipPath>
                <clipPath id="c">
                    <path d="M19.348 13.469l-9.176 11.39-11.39-9.175L7.956 4.293zm0 0"/>
                </clipPath>
                <clipPath id="d">
                    <path d="M19.348 13.469l-9.176 11.39-11.39-9.175L7.956 4.293zm0 0"/>
                </clipPath>
                <clipPath id="e">
                    <path
                        d="M4.742 20.148A7.116 7.116 0 012.945 18a7.214 7.214 0 01-.84-2.676 7.258 7.258 0 01.25-2.79c.133-.452.31-.886.528-1.304.219-.418.476-.812.773-1.18a7.116 7.116 0 012.148-1.797 7.214 7.214 0 012.676-.84 7.258 7.258 0 012.79.25c.453.134.886.31 1.304.528.418.22.813.477 1.18.774.367.293.703.625 1.004.984a7.145 7.145 0 011.629 3.84c.05.469.054.938.015 1.406a7.127 7.127 0 01-.79 2.688 7.249 7.249 0 01-1.758 2.184 7.145 7.145 0 01-2.46 1.344c-.449.14-.91.237-1.378.288a7.258 7.258 0 01-4.098-.777 7.103 7.103 0 01-1.176-.774zm0 0"/>
                </clipPath>
                <linearGradient
                    x1={1744.70638}
                    gradientTransform="rotate(-141.145 105.084 55.584) scale(.11196)"
                    y1={244.834}
                    x2={1925.445736}
                    gradientUnits="userSpaceOnUse"
                    y2={244.834}
                    id="f"
                >
                    <stop stopColor="#000409" offset={0}/>
                    <stop stopColor="#000409" offset={0.125}/>
                    <stop stopColor="#000409" offset={0.140625}/>
                    <stop stopColor="#000409" offset={0.148438}/>
                    <stop stopColor="#00050B" offset={0.152344}/>
                    <stop stopColor="#01050C" offset={0.15625}/>
                    <stop stopColor="#01060C" offset={0.160156}/>
                    <stop stopColor="#01060D" offset={0.164063}/>
                    <stop stopColor="#01070E" offset={0.167969}/>
                    <stop stopColor="#01070F" offset={0.171875}/>
                    <stop stopColor="#010810" offset={0.175781}/>
                    <stop stopColor="#020811" offset={0.179688}/>
                    <stop stopColor="#020912" offset={0.183594}/>
                    <stop stopColor="#020913" offset={0.1875}/>
                    <stop stopColor="#020A14" offset={0.191406}/>
                    <stop stopColor="#020A15" offset={0.195313}/>
                    <stop stopColor="#030B16" offset={0.199219}/>
                    <stop stopColor="#030B16" offset={0.203125}/>
                    <stop stopColor="#030B17" offset={0.207031}/>
                    <stop stopColor="#030C18" offset={0.210938}/>
                    <stop stopColor="#030C19" offset={0.214844}/>
                    <stop stopColor="#040D1A" offset={0.21875}/>
                    <stop stopColor="#040D1B" offset={0.222656}/>
                    <stop stopColor="#040E1C" offset={0.226563}/>
                    <stop stopColor="#040E1D" offset={0.230469}/>
                    <stop stopColor="#040F1E" offset={0.234375}/>
                    <stop stopColor="#050F1F" offset={0.238281}/>
                    <stop stopColor="#051020" offset={0.242188}/>
                    <stop stopColor="#051020" offset={0.246094}/>
                    <stop stopColor="#051121" offset={0.25}/>
                    <stop stopColor="#051122" offset={0.253906}/>
                    <stop stopColor="#051223" offset={0.257813}/>
                    <stop stopColor="#061224" offset={0.261719}/>
                    <stop stopColor="#061225" offset={0.265625}/>
                    <stop stopColor="#061326" offset={0.269531}/>
                    <stop stopColor="#061327" offset={0.273438}/>
                    <stop stopColor="#061428" offset={0.277344}/>
                    <stop stopColor="#071429" offset={0.28125}/>
                    <stop stopColor="#07152A" offset={0.285156}/>
                    <stop stopColor="#07152A" offset={0.289063}/>
                    <stop stopColor="#07162B" offset={0.292969}/>
                    <stop stopColor="#07162C" offset={0.296875}/>
                    <stop stopColor="#08172D" offset={0.300781}/>
                    <stop stopColor="#08172E" offset={0.304688}/>
                    <stop stopColor="#08182F" offset={0.308594}/>
                    <stop stopColor="#081830" offset={0.3125}/>
                    <stop stopColor="#081931" offset={0.316406}/>
                    <stop stopColor="#091932" offset={0.320313}/>
                    <stop stopColor="#091933" offset={0.324219}/>
                    <stop stopColor="#091A34" offset={0.328125}/>
                    <stop stopColor="#091A34" offset={0.332031}/>
                    <stop stopColor="#091B35" offset={0.335938}/>
                    <stop stopColor="#091B36" offset={0.339844}/>
                    <stop stopColor="#0A1C37" offset={0.34375}/>
                    <stop stopColor="#0A1C38" offset={0.347656}/>
                    <stop stopColor="#0A1D39" offset={0.351563}/>
                    <stop stopColor="#0A1D3A" offset={0.355469}/>
                    <stop stopColor="#0A1E3B" offset={0.359375}/>
                    <stop stopColor="#0B1E3C" offset={0.363281}/>
                    <stop stopColor="#0B1F3D" offset={0.367188}/>
                    <stop stopColor="#0B1F3E" offset={0.371094}/>
                    <stop stopColor="#0B203F" offset={0.375}/>
                    <stop stopColor="#0B203F" offset={0.378906}/>
                    <stop stopColor="#0C2040" offset={0.382813}/>
                    <stop stopColor="#0C2141" offset={0.386719}/>
                    <stop stopColor="#0C2142" offset={0.390625}/>
                    <stop stopColor="#0C2243" offset={0.394531}/>
                    <stop stopColor="#0C2244" offset={0.398438}/>
                    <stop stopColor="#0D2345" offset={0.402344}/>
                    <stop stopColor="#0D2346" offset={0.40625}/>
                    <stop stopColor="#0D2447" offset={0.410156}/>
                    <stop stopColor="#0D2448" offset={0.414063}/>
                    <stop stopColor="#0D2549" offset={0.417969}/>
                    <stop stopColor="#0D2549" offset={0.421875}/>
                    <stop stopColor="#0E264A" offset={0.425781}/>
                    <stop stopColor="#0E264B" offset={0.429688}/>
                    <stop stopColor="#0E274C" offset={0.433594}/>
                    <stop stopColor="#0E274D" offset={0.4375}/>
                    <stop stopColor="#0E274E" offset={0.441406}/>
                    <stop stopColor="#0F284F" offset={0.445313}/>
                    <stop stopColor="#0F2850" offset={0.449219}/>
                    <stop stopColor="#0F2951" offset={0.453125}/>
                    <stop stopColor="#0F2952" offset={0.457031}/>
                    <stop stopColor="#0F2A53" offset={0.460938}/>
                    <stop stopColor="#102A53" offset={0.464844}/>
                    <stop stopColor="#102B54" offset={0.46875}/>
                    <stop stopColor="#102B55" offset={0.472656}/>
                    <stop stopColor="#102C56" offset={0.476563}/>
                    <stop stopColor="#102C57" offset={0.480469}/>
                    <stop stopColor="#112D58" offset={0.484375}/>
                    <stop stopColor="#112D59" offset={0.488281}/>
                    <stop stopColor="#112E5A" offset={0.492188}/>
                    <stop stopColor="#112E5B" offset={0.496094}/>
                    <stop stopColor="#112E5C" offset={0.5}/>
                    <stop stopColor="#112F5D" offset={0.503906}/>
                    <stop stopColor="#122F5D" offset={0.507813}/>
                    <stop stopColor="#12305E" offset={0.511719}/>
                    <stop stopColor="#12305F" offset={0.515625}/>
                    <stop stopColor="#123160" offset={0.519531}/>
                    <stop stopColor="#123161" offset={0.523438}/>
                    <stop stopColor="#133262" offset={0.527344}/>
                    <stop stopColor="#133263" offset={0.53125}/>
                    <stop stopColor="#133364" offset={0.535156}/>
                    <stop stopColor="#133365" offset={0.539063}/>
                    <stop stopColor="#133466" offset={0.542969}/>
                    <stop stopColor="#143467" offset={0.546875}/>
                    <stop stopColor="#143567" offset={0.550781}/>
                    <stop stopColor="#143568" offset={0.554688}/>
                    <stop stopColor="#143569" offset={0.558594}/>
                    <stop stopColor="#14366A" offset={0.5625}/>
                    <stop stopColor="#14366B" offset={0.566406}/>
                    <stop stopColor="#15376C" offset={0.570313}/>
                    <stop stopColor="#15376D" offset={0.574219}/>
                    <stop stopColor="#15386E" offset={0.578125}/>
                    <stop stopColor="#15386F" offset={0.582031}/>
                    <stop stopColor="#153970" offset={0.585938}/>
                    <stop stopColor="#163971" offset={0.589844}/>
                    <stop stopColor="#163A71" offset={0.59375}/>
                    <stop stopColor="#163A73" offset={0.597656}/>
                    <stop stopColor="#163B74" offset={0.601562}/>
                    <stop stopColor="#163C76" offset={0.605469}/>
                    <stop stopColor="#163C77" offset={0.609375}/>
                    <stop stopColor="#173D79" offset={0.613281}/>
                    <stop stopColor="#173E7A" offset={0.617188}/>
                    <stop stopColor="#173E7B" offset={0.621094}/>
                    <stop stopColor="#173F7D" offset={0.625}/>
                    <stop stopColor="#17407E" offset={0.628906}/>
                    <stop stopColor="#174080" offset={0.632813}/>
                    <stop stopColor="#184181" offset={0.636719}/>
                    <stop stopColor="#184283" offset={0.640625}/>
                    <stop stopColor="#184284" offset={0.644531}/>
                    <stop stopColor="#184386" offset={0.648438}/>
                    <stop stopColor="#184387" offset={0.652344}/>
                    <stop stopColor="#184489" offset={0.65625}/>
                    <stop stopColor="#18458A" offset={0.660156}/>
                    <stop stopColor="#19458C" offset={0.664063}/>
                    <stop stopColor="#19468D" offset={0.667969}/>
                    <stop stopColor="#19478F" offset={0.671875}/>
                    <stop stopColor="#194790" offset={0.675781}/>
                    <stop stopColor="#194891" offset={0.679688}/>
                    <stop stopColor="#194993" offset={0.683594}/>
                    <stop stopColor="#1A4994" offset={0.6875}/>
                    <stop stopColor="#1A4A96" offset={0.691406}/>
                    <stop stopColor="#1A4B97" offset={0.695312}/>
                    <stop stopColor="#1A4B99" offset={0.699219}/>
                    <stop stopColor="#1A4C9A" offset={0.703125}/>
                    <stop stopColor="#1A4D9C" offset={0.707031}/>
                    <stop stopColor="#1A4D9D" offset={0.710938}/>
                    <stop stopColor="#1B4E9F" offset={0.714844}/>
                    <stop stopColor="#1B4FA0" offset={0.71875}/>
                    <stop stopColor="#1B4FA2" offset={0.722656}/>
                    <stop stopColor="#1B50A3" offset={0.726563}/>
                    <stop stopColor="#1B51A5" offset={0.730469}/>
                    <stop stopColor="#1B51A6" offset={0.734375}/>
                    <stop stopColor="#1C52A7" offset={0.738281}/>
                    <stop stopColor="#1C53A9" offset={0.742188}/>
                    <stop stopColor="#1C53AA" offset={0.746094}/>
                    <stop stopColor="#1C54AC" offset={0.75}/>
                    <stop stopColor="#1C55AD" offset={0.753906}/>
                    <stop stopColor="#1C55AF" offset={0.757813}/>
                    <stop stopColor="#1C56B0" offset={0.761719}/>
                    <stop stopColor="#1D57B2" offset={0.765625}/>
                    <stop stopColor="#1D57B3" offset={0.769531}/>
                    <stop stopColor="#1D58B5" offset={0.773438}/>
                    <stop stopColor="#1D59B6" offset={0.777344}/>
                    <stop stopColor="#1D59B8" offset={0.78125}/>
                    <stop stopColor="#1D5AB9" offset={0.785156}/>
                    <stop stopColor="#1E5BBB" offset={0.789062}/>
                    <stop stopColor="#1E5BBC" offset={0.792969}/>
                    <stop stopColor="#1E5CBD" offset={0.796875}/>
                    <stop stopColor="#1E5DBF" offset={0.800781}/>
                    <stop stopColor="#1E5DC0" offset={0.804688}/>
                    <stop stopColor="#1E5EC2" offset={0.808594}/>
                    <stop stopColor="#1E5FC3" offset={0.8125}/>
                    <stop stopColor="#1F5FC5" offset={0.816406}/>
                    <stop stopColor="#1F60C6" offset={0.820312}/>
                    <stop stopColor="#1F61C8" offset={0.824219}/>
                    <stop stopColor="#1F61C9" offset={0.828125}/>
                    <stop stopColor="#1F62CB" offset={0.832031}/>
                    <stop stopColor="#1F63CC" offset={0.835938}/>
                    <stop stopColor="#2063CE" offset={0.839844}/>
                    <stop stopColor="#2064CF" offset={0.84375}/>
                    <stop stopColor="#2065D0" offset={0.847656}/>
                    <stop stopColor="#2065D1" offset={0.851562}/>
                    <stop stopColor="#2065D1" offset={0.859375}/>
                    <stop stopColor="#2065D1" offset={0.875}/>
                    <stop stopColor="#2065D1" offset={1}/>
                </linearGradient>
                <clipPath id="g">
                    <path d="M4.27 9.652H7V13H4.27zm0 0"/>
                </clipPath>
                <clipPath id="h">
                    <path d="M4.27 9.652H11V16H4.27zm0 0"/>
                </clipPath>
                <clipPath id="i">
                    <path d="M11 16h3.512v3.16H11zm0 0"/>
                </clipPath>
                <clipPath id="j">
                    <path d="M8 13h6.512v6.16H8zm0 0"/>
                </clipPath>
                <clipPath id="k">
                    <path d="M4.27 9.652h10.242v9.508H4.27zm0 0"/>
                </clipPath>
            </defs>
            <path
                d="M1.219 0v-14.25H5.14V0zm0 0"
                transform="translate(15.736 21.618)"
                fill={color}
            />
            <path
                d="M11.578 0H7.766v-5.938c0-.519-.121-.894-.36-1.125-.23-.238-.496-.359-.797-.359-.324 0-.668.14-1.03.422-.356.281-.622.64-.798 1.078V0H.97v-10.563H4.39V-9c.375-.57.898-1.008 1.578-1.313.676-.3 1.476-.453 2.406-.453.719 0 1.285.133 1.703.391.426.262.75.59.969.984.226.387.375.805.437 1.25.063.438.094.836.094 1.188zm0 0"
                transform="translate(21.96 21.618)"
                fill={color}
            />
            <path
                d="M5.375.203a4.921 4.921 0 01-2-.406 4.78 4.78 0 01-1.578-1.125 5.361 5.361 0 01-1.031-1.75c-.243-.676-.36-1.414-.36-2.219 0-.781.11-1.504.328-2.172a5.476 5.476 0 01.954-1.734 4.47 4.47 0 011.468-1.14 4.091 4.091 0 011.86-.423c.687 0 1.312.157 1.875.47.57.304 1.015.733 1.328 1.296v-5.656h3.812V-4.22c0 .367.063.621.188.766.133.137.379.215.734.234V0c-.387.063-.73.11-1.031.14-.293.04-.555.063-.781.063-1.293 0-2.043-.488-2.25-1.469l-.079-.375c-.367.637-.855 1.106-1.468 1.407a4.588 4.588 0 01-1.969.437zm1.047-3.219c.695 0 1.3-.359 1.812-1.078v-1.89a2.62 2.62 0 00-.828-1.125c-.367-.29-.746-.438-1.14-.438a1.66 1.66 0 00-.782.188 1.69 1.69 0 00-.593.5c-.168.21-.305.453-.407.734a2.86 2.86 0 00-.14.906c0 .668.187 1.203.562 1.61.383.398.89.593 1.516.593zm0 0"
                transform="translate(34.289 21.618)"
                fill={color}
            />
            <path
                d="M6.156.203c-.949 0-1.781-.152-2.5-.453A5.437 5.437 0 011.86-1.453 4.915 4.915 0 01.75-3.203a5.86 5.86 0 01-.375-2.078C.375-6 .5-6.691.75-7.36a4.95 4.95 0 011.11-1.75c.488-.5 1.085-.899 1.796-1.204.719-.3 1.551-.453 2.5-.453.938 0 1.766.153 2.485.454.726.304 1.336.703 1.828 1.203.488.5.86 1.086 1.11 1.75.25.668.374 1.359.374 2.078a5.86 5.86 0 01-.375 2.078 4.97 4.97 0 01-1.11 1.75c-.491.5-1.1.902-1.827 1.203-.72.3-1.547.453-2.485.453zM4.296-5.28c0 .71.173 1.265.516 1.672.352.398.801.593 1.344.593.551 0 1-.195 1.344-.593.352-.407.531-.961.531-1.672 0-.707-.18-1.258-.531-1.657-.344-.406-.793-.609-1.344-.609a1.7 1.7 0 00-1.343.61c-.344.398-.516.949-.516 1.656zm0 0"
                transform="translate(47.641 21.618)"
                fill={color}
            />
            <path
                d="M.375-5.281C.375-6 .5-6.691.75-7.36a5.1 5.1 0 011.125-1.75c.488-.5 1.094-.899 1.813-1.204.726-.3 1.562-.453 2.5-.453 1.28 0 2.351.274 3.218.813.864.531 1.508 1.219 1.938 2.062L7.625-6.766c-.355-.52-.844-.78-1.469-.78-.523 0-.965.198-1.328.593-.355.398-.531.953-.531 1.672 0 .718.176 1.277.531 1.672.363.398.805.593 1.328.593.625 0 1.114-.257 1.469-.78l3.719 1.124c-.43.844-1.074 1.54-1.938 2.078-.867.531-1.937.797-3.219.797-.937 0-1.773-.152-2.5-.453a5.427 5.427 0 01-1.812-1.203A5.064 5.064 0 01.75-3.203a5.86 5.86 0 01-.375-2.078zm0 0"
                transform="translate(59.829 21.618)"
                fill={color}
            />
            <path
                d="M.969 0v-10.563H4.78V0zm0-11.438v-3.218H4.78v3.219zm0 0"
                transform="translate(71.254 21.618)"
                fill={color}
            />
            <g clipPath="url(#a)">
                <path
                    d="M4.078.203c-.523 0-1.012-.09-1.469-.266a3.84 3.84 0 01-1.187-.703c-.324-.3-.578-.66-.766-1.078a3.223 3.223 0 01-.281-1.343c0-.54.113-1.032.344-1.47.226-.445.539-.827.937-1.14.407-.32.883-.57 1.438-.75a6.148 6.148 0 011.844-.266c.851 0 1.562.137 2.125.407v-.313c0-.5-.149-.867-.438-1.11-.281-.237-.746-.359-1.39-.359-.594 0-1.15.102-1.657.297a7.729 7.729 0 00-1.61.844L.86-9.422c1.395-.894 2.985-1.344 4.766-1.344 1.688 0 2.984.375 3.89 1.125.907.75 1.36 1.875 1.36 3.375v2.047c0 .367.066.621.203.766.133.137.379.215.735.234V0c-.356.063-.688.11-1 .14-.305.04-.575.063-.813.063-.688 0-1.21-.125-1.563-.375-.355-.25-.585-.613-.687-1.094l-.078-.375A5.054 5.054 0 016.047-.266a4.299 4.299 0 01-1.969.47zm1.14-2.734c.227 0 .462-.035.704-.11.238-.07.453-.171.64-.296.145-.102.266-.22.36-.344a.64.64 0 00.14-.39v-.782a5.5 5.5 0 00-.75-.203 3.748 3.748 0 00-.703-.078c-.468 0-.859.117-1.171.343-.313.22-.47.508-.47.86 0 .293.114.531.345.719.238.187.539.28.906.28zm0 0"
                    transform="translate(76.856 21.618)"
                    fill={color}
                />
            </g>
            <g clipPath="url(#b)">
                <g clipPath="url(#c)">
                    <g clipPath="url(#d)">
                        <g clipPath="url(#e)">
                            <path
                                fill="url(#f)"
                                d="M10.781 28.781l12.692-15.758L7.715.332-4.98 16.09zm0 0"
                            />
                        </g>
                    </g>
                </g>
            </g>
            <g clipPath="url(#g)">
                <path
                    fill="#fafafa"
                    d="M4.54 10.844l.972-.969a.743.743 0 01.539-.215c.21.004.386.078.535.227a.744.744 0 01.226.535c0 .21-.07.39-.214.539l-.97.973a.739.739 0 01-.542.21.732.732 0 01-.535-.222.732.732 0 01-.223-.535.739.739 0 01.211-.543zm0 0"
                />
            </g>
            <g clipPath="url(#h)">
                <path
                    fill="#fafafa"
                    d="M4.61 14.453l4.558-4.555a.743.743 0 01.539-.214.751.751 0 01.535.226.751.751 0 01.227.535c0 .211-.07.39-.215.54l-4.555 4.558a.748.748 0 01-.547.234.734.734 0 01-.55-.226.728.728 0 01-.227-.551c0-.215.082-.395.234-.547zm0 0"
                />
            </g>
            <g clipPath="url(#i)">
                <path
                    fill="#fafafa"
                    d="M13.91 18.273l-.973.97a.726.726 0 01-.542.226.738.738 0 01-.543-.227.737.737 0 01-.223-.543c0-.21.074-.39.223-.543l.972-.968a.743.743 0 01.54-.215.751.751 0 01.534.226.744.744 0 01.227.535c0 .207-.07.387-.215.54zm0 0"
                />
            </g>
            <g clipPath="url(#j)">
                <path
                    fill="#fafafa"
                    d="M13.934 14.664l-4.559 4.559a.727.727 0 01-.54.215.74.74 0 01-.534-.223.753.753 0 01-.227-.54c0-.206.07-.386.215-.538l4.555-4.559a.758.758 0 01.543-.215.751.751 0 01.535.227.726.726 0 01.223.535.736.736 0 01-.211.54zm0 0"
                />
            </g>
            <g clipPath="url(#k)">
                <path
                    fill="#fafafa"
                    d="M14.172 10.559v1.203a.7.7 0 01-.152.445.7.7 0 01-.391.258.692.692 0 01-.469-.035.695.695 0 01-.348-.313l-7.054 7.04a.713.713 0 01-.54.214.747.747 0 01-.534-.223.753.753 0 01-.227-.539c0-.207.07-.386.215-.539l7.016-7.011a.71.71 0 01-.364-.329.743.743 0 01-.07-.484.71.71 0 01.254-.418.716.716 0 01.46-.164h1.34a.73.73 0 01.356.031.786.786 0 01.305.196.724.724 0 01.18.308c.039.117.042.239.023.36zm0 0"
                />
            </g>
        </Svg>
    );
}

export default LogoSvg;

const Svg = styled("svg")(() => ({
    height: 80,
    width: 150
}));
