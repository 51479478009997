import * as React from "react";
import {Svg} from "./flutter";

function PayU(props) {
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            sx={{transform: "scale(0.45)"}}
            viewBox="0 0 335 170"
            xmlSpace="preserve"
            {...props}>
            <path d="M330.729 37.026h-14.82c-1.559 0-2.73-1.17-2.73-2.73V19.474c0-1.56 1.172-2.73 2.73-2.73h14.82c1.561 0 2.732 1.171 2.732 2.73v14.822c0 1.17-1.172 2.73-2.732 2.73M311.229 16.354h-10.143c-1.172 0-1.949-.78-1.949-1.95V4.263c0-1.171.777-1.95 1.949-1.95h10.143c1.172 0 1.949.779 1.949 1.95v10.142c0 .779-1.172 1.949-1.949 1.949" />
            <defs>
                <path
                    id="a"
                    d="M20.651 55.747c-4.291 0-5.852 1.561-5.852 5.852v30.813h24.572c15.992 0 19.112-6.633 19.112-18.332 0-15.211-7.021-18.332-19.112-18.332h-18.72M8.95 139.213H7.39c-4.29 0-5.851-1.557-5.851-5.849V60.038c0-11.31 5.069-16.382 16.382-16.382h21.451c21.843 0 32.374 9.752 32.374 30.423 0 21.063-10.142 30.813-32.374 30.813H14.8v28.472c0 4.292-1.17 5.849-5.85 5.849m246.114 1.172c-18.725 0-31.984-5.85-39.006-16.382-3.508-5.461-5.457-12.481-5.457-20.281V53.017c0-8.19 3.508-9.749 12.09-9.749h2.73c8.969 0 12.09 1.559 12.09 9.749v50.316c0 3.116.781 5.849 1.949 7.8 2.34 3.901 7.412 5.46 14.82 5.46 7.803 0 12.484-1.559 14.822-5.46 1.168-1.951 1.951-4.684 1.951-7.8V53.017c0-8.19 3.51-9.749 12.09-9.749h1.563v17.159c0 2.341 1.559 3.902 3.9 3.902h9.75v38.613c0 8.19-1.951 14.821-5.461 20.279-5.852 11.314-19.11 17.164-37.831 17.164m-153.285-34.322c-12.871 0-17.942 3.118-17.942 11.7 0 7.801 5.071 11.311 15.211 11.311 15.604 0 18.722-4.682 18.722-12.871v-10.14h-15.991m-2.341 34.322c-17.941 0-28.082-8.192-28.082-22.621 0-14.821 9.75-21.842 30.423-21.842h15.99v-7.8c0-8.582-5.07-12.093-17.942-12.093-7.019 0-13.649 1.172-15.6 1.561-.78.389-1.561.389-2.341.389-1.17 0-2.341-.389-2.73-1.17-.389-.779-.78-1.95-.78-3.51v-1.17c0-3.9 1.561-5.07 5.071-5.85 1.95-.392 7.411-1.562 15.601-1.562 21.063 0 31.203 7.8 31.203 23.404v28.471c.39 16.383-9.361 23.793-30.813 23.793m55.775 27.303c-3.509 0-4.289-1.949-5.07-4.682l-.389-1.171c-.392-1.168-.392-2.728.389-3.899.781-1.169 1.95-1.169 4.291-1.559 10.531-1.171 14.041-5.849 17.553-16.771-1.563.389-3.512.778-5.853.778-11.312 0-14.43-7.8-17.161-17.551l-13.65-50.316c-.392-1.559-.78-3.509 0-4.68.779-1.17 2.729-1.17 4.29-1.17h1.171c4.289 0 5.85 1.17 7.019 5.461l14.043 49.534c1.559 6.24 3.51 7.411 7.8 7.411 3.51 0 5.85-1.171 7.021-7.411L188.76 71.74c.777-4.291 2.727-5.461 7.018-5.461h1.17c1.561 0 3.512 0 4.291 1.17s.779 2.729.391 4.68l-16.77 66.307c-4.682 19.5-13.264 27.692-28.867 28.863-.001.389-.389.389-.78.389"
                />
            </defs>
            <clipPath id="b">
                <use xlinkHref="#a" overflow="visible" />
            </clipPath>
            <linearGradient
                id="c"
                gradientUnits="userSpaceOnUse"
                x1={-93.4858}
                y1={-20.2803}
                x2={-92.577}
                y2={-20.2803}
                gradientTransform="rotate(90 -7867.275 5198.49) scale(139.017)">
                <stop offset={0} />
                <stop offset={1} />
            </linearGradient>
            <path clipPath="url(#b)" d="M1.929 43.656H299.137V167.687H1.929z" />
            <path d="M309.279 64.718h-10.143V53.017c0-8.19-3.51-9.749-12.094-9.749h-1.557v-2.731c0-2.341 1.557-3.899 3.9-3.899h19.893c2.338 0 3.898 1.559 3.898 3.899v19.891c.002 2.34-1.947 4.29-3.897 4.29" />
            <g>
                <defs>
                    <path
                        id="d"
                        d="M299.137 64.718h-9.75c-2.344 0-3.9-1.56-3.9-3.898V43.656h1.557c8.973 0 12.094 1.561 12.094 9.752v11.31"
                    />
                </defs>
                <clipPath id="e">
                    <use xlinkHref="#d" overflow="visible" />
                </clipPath>
                <linearGradient
                    id="f"
                    gradientUnits="userSpaceOnUse"
                    x1={-93.4854}
                    y1={-21.3003}
                    x2={-92.5718}
                    y2={-21.3003}
                    gradientTransform="rotate(90 -7867.275 5198.49) scale(139.017)">
                    <stop offset={0} />
                    <stop offset={1} />
                </linearGradient>
                <path
                    clipPath="url(#e)"
                    d="M285.486 43.656H299.13599999999997V64.718H285.486z"
                />
            </g>
        </Svg>
    );
}

export default PayU;
