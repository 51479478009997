import * as React from "react";
import {Svg} from "./flash";

function Lock(props) {
    return (
        <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            <path d="M27.15 20.35c0-3.11-1.28-5.92-3.33-7.94V8.32c0-2.08-.81-4.04-2.29-5.54A7.827 7.827 0 0016 .5c-4.31 0-7.82 3.51-7.82 7.82v4.09a11.118 11.118 0 00-3.33 7.94c0 6.15 5 11.15 11.15 11.15s11.15-5 11.15-11.15zM16 22.79c-1.35 0-2.44-1.09-2.44-2.44 0-1.35 1.09-2.45 2.44-2.45s2.44 1.1 2.44 2.45-1.09 2.44-2.44 2.44zM11.18 8.32c0-2.66 2.16-4.82 4.82-4.82 1.28 0 2.49.5 3.4 1.41a4.79 4.79 0 011.42 3.41v1.98c-1.46-.7-3.09-1.11-4.82-1.11s-3.36.41-4.82 1.11V8.32z" />
        </Svg>
    );
}

export default Lock;
